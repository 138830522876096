import React from 'react'

function PageNotFound() {
    return (
    <div>
        <h1>404</h1>
        <h2>Page not found</h2>
    </div>
    )
}

export default PageNotFound
